import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { share } from 'rxjs/operators';

@Injectable({
providedIn: 'root'
})
export class UsersService {

	@Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
	@Output() head2List: EventEmitter<any> = new EventEmitter();
	@Output() updateHeader: EventEmitter<any> = new EventEmitter();

	baseAPI: string = environment.baseURL;

	constructor(private httpClient: HttpClient) { }

	public getCoreAllUsers():Observable<any>{
		return this.httpClient.post(`${this.baseAPI}/getCoreAllUsers`,{});
	}

	public getCoreUserById(id: string):Observable<any>{
		return this.httpClient.post(`${this.baseAPI}/getCoreUserById`,{id: id});
	}

	// data: id, jwt, firstName, lastName, phone
	public updateCoreUser(data: any): Observable<any>{
		const result = this.httpClient.post(`${this.baseAPI}/updateCoreUser`, data);
		// this.getLoggedInName.emit(localStorage.getItem('token'));
		this.head2List.emit();
		this.updateHeader.emit(result);
		return result;
	}

	public toggleCoreUserActive(data: any): Observable<any>{
		return this.httpClient.post(`${this.baseAPI}/toggleCoreUserActive`, data);
	}

	public toggleUserShop(data: any): Observable<any>{
		return this.httpClient.post(`${this.baseAPI}/toggleUserShop`, data);
	}

	public deleteUserShop(data: any): Observable<any>{
		return this.httpClient.post(`${this.baseAPI}/deleteShop`, data);
	}

	public toggleUserProvider(data: any): Observable<any>{
		return this.httpClient.post(`${this.baseAPI}/toggleUserProvider`, data);
	}


	//data {jwt, pass}, out: success or not
	public updatePassByUser(data: any): Observable<any>{
		return this.httpClient.post(`${this.baseAPI}/updateCorePassword`, data)
	}

	public forgetCorePassword(email: string): Observable<any>{
		return this.httpClient.post(`${this.baseAPI}/forgetCorePassword`, {email: email});
	}

    public deleteCoreUser(email: string, password: string): Observable<any>{
		return this.httpClient.post(`${this.baseAPI}/delete-account`, {email: email, pass: password});
	}

	public resetCorePassword(token: string, pass: string): Observable<any>{
		return this.httpClient.post(`${this.baseAPI}/resetCorePassword`, {token: token, pass: pass});
	}

	public getUserProviderSet(network: string): Observable<any>{
		return this.httpClient.post(`${this.baseAPI}/getUserProviderSet`, {network: network});
	}

	public getUserShopSet(network: string): Observable<any>{
		return this.httpClient.post(`${this.baseAPI}/getUserShopSet`, {network: network});
	}

	public getShopDetailById(id: string):Observable<any>{
		return this.httpClient.post(`${this.baseAPI}/getShopDetailById`,{id: id});
	}

	public getProviderDetailById(id: string): Observable<any>{
		return this.httpClient.post(`${this.baseAPI}/getProviderDetailById`,{id: id});
	}

	public createShop(data: any): Observable<any>{
		return this.httpClient.post(`${this.baseAPI}/createShop`, data);
	}

	public createProvider(data: any): Observable<any>{
		return this.httpClient.post(`${this.baseAPI}/createProvider`, data);
	}

	public modifyProvider(data: any): Observable<any>{
		const result = this.httpClient.post(`${this.baseAPI}/modifyProvider`, data);
		if (data.fromAccountModification) {
			this.updateHeader.emit(result);
		}
		return result
	}

	public modifyShop(data: any): Observable<any>{
		const result = this.httpClient.post(`${this.baseAPI}/modifyShop`, data).pipe(share());
		if (data.fromAccountModification) {
			this.updateHeader.emit(result);
		}
		return result;
	}

	public modifyProviderAddress(data: any): Observable<any>{
		const result = this.httpClient.post(`${this.baseAPI}/modifyProviderAddress`, data);
		if (data.fromAccountModification) {
			this.updateHeader.emit(result);
		}
		return result
	}

	public modifyShopAddress(data: any): Observable<any>{
		const result = this.httpClient.post(`${this.baseAPI}/modifyShopAddress`, data);
		if (data.fromAccountModification) {
			this.updateHeader.emit(result);
		}
		return result;
	}

	public modifyMangoInfo(data: any): Observable<any>{
		const result = this.httpClient.post(`${this.baseAPI}/modifyMangoInfo`, data);
		return result;
	}

	public modifyBankInfo(data: any): Observable<any>{
		const result = this.httpClient.post(`${this.baseAPI}/modifyBankInfo`, data);
		return result;
	}

	public modifyKYC(docType: string, data: object): Observable<any>{

		const result = this.httpClient.post(`${this.baseAPI}/modifyKYC${docType === 'IDENTITY_PROOF' ? 'Identity' : 'Address'}`, data);
		return result;
	}
}
