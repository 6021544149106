import { UsersService } from './../../../bo/services/users.service';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../../services/nav.service';
import { AuthService } from "../../../bo/services/auth.service";
import { BlockUI, NgBlockUI } from "ng-block-ui";

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {

    public menuItems: Menu[];
    public url: any;
    public fileurl: any;

    @BlockUI() blockUI: NgBlockUI;

    constructor(
        private router: Router,
        public navServices: NavService,
        public authService: AuthService,
    ) {
        authService.getLoggedInName.subscribe((token) => {
            this.getListNavBar(token);
        });
        authService.getNavBarList.subscribe((token) => {
            this.getListNavBar(token);
        });
    }

    getListNavBar(token: string): void {
        this.navServices.items.subscribe(menuItems => {
            this.menuItems = menuItems;
            this.checkRole(token);
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    menuItems.filter(items => {
                        if (items.path === event.url)
                            this.setNavActive(items)
                        if (!items.children) return false
                        items.children.filter(subItems => {
                            if (subItems.path === event.url)
                                this.setNavActive(subItems)
                            if (!subItems.children) return false
                            subItems.children.filter(subSubItems => {
                                if (subSubItems.path === event.url)
                                    this.setNavActive(subSubItems)
                            })
                        })
                    })
                }
            })
        })
    }

    ngOnInit(): void {
        this.getListNavBar(localStorage.getItem('token'));
    }

    checkRole(token: string): void {
        this.blockUI.start('Loading');
        this.authService.decodeJWT(token).subscribe(
            (res) => {
                if (res.success) {
                    if (res.data.role === 'Admin') {
                        this.menuItems = this.menuItems.filter(item => item.title != 'Utilisateurs')
                        this.menuItems = [...this.menuItems,
                        // { path: '', icon: 'users', title: 'Utilisateurs', type: 'sub', active: false,
                        //   children:[
                        { path: '/bo/fournisseurs', icon: 'users', title: 'Fournisseurs', type: 'link' },
                        { path: '/bo/adherents', icon: 'users', title: 'Adhérents', type: 'link' },
                        // { path: '/bo/utilisateurs', icon: 'users', title: 'Utilisateurs', type: 'link' },
                        //  { path: '/bo/boutiques', icon: 'users', title: 'Boutiques', type: 'link' },
                        { path: '/bo/etat-systeme', icon: 'activity', title: 'Etat Système', type: 'link' },

                            // ]
                            // }
                        ]
                        this.blockUI.stop();
                    }

                }
                else {
                    this.menuItems = this.menuItems.filter(item => item.title != 'Utilisateurs');
                    this.menuItems = [...this.menuItems,
                    { path: '/bo/clients', icon: 'users', title: 'Utilisateurs', type: 'link' },
                    ]
                    this.blockUI.stop();
                }
            },
            (error) => {
                this.blockUI.stop();
            }
        );
    }
    // Active Nave state
    setNavActive(item) {
        // this.menuItems.filter(menuItem => {
        //   if (menuItem != item)
        //     menuItem.active = false
        //   if (menuItem.children && menuItem.children.includes(item))
        //     menuItem.active = true
        //   if (menuItem.children) {
        //     menuItem.children.filter(submenuItems => {
        //       if (submenuItems.children && submenuItems.children.includes(item)) {
        //         menuItem.active = true
        //         submenuItems.active = true
        //       }
        //     })
        //   }
        // })
    }

    // Click Toggle menu
    toggletNavActive(item) {
        // if (!item.active) {
        //   this.menuItems.forEach(a => {
        //     if (this.menuItems.includes(item))
        //       a.active = false
        //     if (!a.children) return false
        //     a.children.forEach(b => {
        //       if (a.children.includes(item)) {
        //         b.active = false
        //       }
        //     })
        //   });
        // }
        // item.active = !item.active
    }

    //Fileupload
    readUrl(event: any) {
        if (event.target.files.length === 0)
            return;
        //Image upload validation
        var mimeType = event.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            return;
        }
        // Image upload
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
            this.url = reader.result;
        }
    }

}
